<template>
  <div class="advance" ref="advance">
    <div class="advance-banner">
      <img src="https://cdn.cailu88.com/jingxuanshi/client_activity_12_advance.png"/>
    </div>
    <div class="advance-button">
      <van-button @click="isAuth">进入主会场
        <van-icon name="arrow"/>
      </van-button>
    </div>
    <div class="category-list">
      <template v-for="(item, index) in categoryList">
        <div :key="index" :class="[item.selected ? 'scroll-view-item-active' : '','scroll-view-item']"
             @click="handleChangeCat(item.code)">
          <p>{{ item.desc }}</p>
          <div v-if="item.selected" class="active-tab"></div>
        </div>
      </template>
    </div>
    <div class="goods-list">
      <template v-for="(item, index) in goodsList">
        <div class="goods-wrap" :key="index" @click="goodsDetails(item)">
          <div class="goods-image">
            <img v-lazy="item.pictUrl"/>
          </div>
          <div class="goods-info">
            <div class="goods-title">
              <span
                :class="item.itemSource === 3 ? 'label-pdd' : item.itemSource === 4 ? 'label-wph' : item.itemSource === 5 ? 'label-sn' : item.itemSource === 2 ? 'label-jd' : item.itemSource === 1 && item.userType == 0 ? 'label-tb' : item.itemSource === 1 && item.userType == 1 ? 'label-tm' : ''">{{
                  item.itemSource === 3 ? '拼多多' : item.itemSource === 4 ? '唯品会' : item.itemSource === 5 ? '苏宁' : item.itemSource === 2 ? '京东' : item.itemSource === 1 && item.userType == 0 ? '淘宝' : item.itemSource === 1 && item.userType == 1 ? '天猫' : ''
                }}</span>
              <!-- <image class="jd-icon" azy-load="true" src="https://cdn.cailu88.com/jingxuanshi/jd_icon@2x.png"></image> -->
              <span>{{ item.itemTitle }}</span>
            </div>
            <div class="goods-discounts">
              <div class="goods-coupon" v-if="item.couponValue">
                <span></span>{{ item.couponValue }}元券
              </div>
              <div v-if="item.rebateValue>0" class="goods-cashback">返 ¥{{ item.rebateValue }}</div>
            </div>
            <div class="goods-price-volume">
              <div class="price">¥ {{ item.reservePrice }}</div>
              <div class="volume" v-if="item.volume && !free">
                <span>{{ item.volume }}人</span>
                <span> 已购</span>
              </div>
            </div>
            <div class="goods-promotion-volume">
              <div>
                <span class='money-symbol'>¥ </span>
                <span class="goods-current-price">{{ item.zkFinalPrice }}</span>
              </div>
              <div class="volume" v-if="item.volume && free">
                <span v-if="item.volume < 10000">{{ item.volume }}人</span>
                <span v-else>{{ parseFloat((item.volume / 10000).toFixed(2)) }}万人</span>
                <span> 已购</span>
              </div>
            </div>
          </div>
        </div>
      </template>
      <div :class="['loading-wrap', goodsList.length < 5 ? 'loading-wrap-white':'']">
        <img v-if="goodsList.length < totalCount" class="loading"
             src="https://cdn.cailu88.com/jingxuanshi/loading@2x.png"/>
        <p v-if="goodsList.length === totalCount">
          暂无更多
        </p>
      </div>
    </div>
<!--    <div class="bottom">-->
<!--      <van-button v-if="!authStatus" type="primary" @click="isAuth">分享预售会场口令</van-button>-->
<!--      <van-button v-else @click="copyText($event)">分享预售会场口令</van-button>-->
<!--    </div>-->
    <Loading :click="click"></Loading>
  </div>
</template>
<script>
import Vue from 'vue'
import { Field, Lazyload, Button, Icon, Toast } from 'vant'
import Loading from '../../components/loading'
import { hybrid } from 'hybrid-api'
import baseUrl from '@/utils/baseUrl'
import clipboard from '@/utils/copy'

const api = require('../../utils/api').api

Vue.use(Field)
Vue.use(Button)
Vue.use(Icon)
Vue.use(Toast)
Vue.use(Lazyload, {
  lazyComponent: true,
})
export default {
  name: 'advance',
  data () {
    return {
      authStatus: false,
      id: '20150318020001158',
      url: '',
      shareContent: '',
      token: '',
      version: '',
      appClient: '',
      free: true,
      click: true,
      catId: '38883',
      page: 1,
      pageSize: 20,
      totalCount: 0,
      goodsList: [],
      categoryList: [],           // 类目数组
    }
  },
  created () {
    this.token = this.$route.query.token || '';
    this.version = this.$route.query.version || '';
    this.channel = this.$route.query.channel || '';
    this.appClient = this.$route.query.appClient || '';
    this.handleGetActivityMaterials()
    this.handleGetGoodsList()
  },
  mounted () {
    window.addEventListener('scroll', this.lazyLoading) // 滚动到底部，再加载的处理事件
    this.$once('hook:beforeDestroy', () => {
      window.removeEventListener('scroll', this.lazyLoading)
    });
  },
  components: {
    Loading
  },
  methods: {

    //复制
    copyText (event) {
      let that = this
      if (!this.click) {
        return false
      }
      this.click = true
      this.$.ajax({
        url: baseUrl.baseUrl + api.auth,
        type: 'get',
        headers: {
          'token': that.token,
          'version': that.version,
          'channel': that.channel,
          'appClient': that.appClient,
          'Content-Type': 'application/json;charset=UTF-8'
        },
        async: false,
        success: function (res) {
          if (res.code !== 'USER_UN_LOGIN' && res.code) {
            if (res.data.authStatus == 0) {
              that.click = true
              that.$h5AppLink('openTbAuth', {
                authUrl: res.data.authUrl,
                backUrl: `${baseUrl.pageUrl}/advance`
              })
            } else {
              that.$.ajax({
                url: baseUrl.baseUrl + api.strategyUrl,
                type: 'post',
                headers: {
                  'token': that.token,
                  'version': that.version,
                  'channel': that.channel,
                  'appClient': that.appClient,
                  'Content-Type': 'application/json;charset=UTF-8'
                },
                data: JSON.stringify({
                  shareScene: true,
                  tbActivityMaterialId: -1,
                  initUrl: 'https://mo.m.taobao.com/union/yushoujianhuotob?spm=a211vu.11758832.0.0.35cb3e6fl9iGrv&__t__=1606744667553&show_tab=0',
                  clientType: 2
                }),
                async: false,
                success: function (res_t) {
                  that.click = true
                  Toast.success({
                    duration: 500, // 持续展示 toast
                    message: '复制成功'
                  })
                  that.shareContent = res_t.data.shareContent
                }
              })
            }
          } else {
            that.click = true
            that.isLogin()
            return false
          }

        }
      })
      this.click = true
      clipboard(this.copyContent, event)
    },

    handleGetActivityMaterials () {
      this.$axios(api.activityMaterials, {}).then(res => {
        this.categoryList = res.data.data
        this.categoryList.map(item => {
          if (item.code == '38883') {
            item.selected = true
          } else {
            item.selected = false
          }
        })
      }).catch(err => {
        this.isLogin();
        console.info(err)
      })
    },
    lazyLoading () {
      // 获取 可视区高度`、`滚动高度`、`页面高度`
      let timer = null
      let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
      let clientHeight = document.documentElement.clientHeight
      let scrollHeight = document.documentElement.scrollHeight
      this.scroll = scrollHeight
      this.height = scrollTop + clientHeight
      if (scrollTop + clientHeight >= scrollHeight) { // 滚动到底部，逻辑代码
        //事件处理
        this.page++
        this.handleGetGoodsList('up')
        // if (this.goodsList.length < this.totalCount && this.totalCount !== 0){
        // }
      }
    },
    handleGetGoodsList (type) {
      if (!this.click) {
        return false
      }
      let that = this
      this.click = false
      this.$axios(api.activityMaterialsGoods, {
        params: {
          // activityId: this.id,
          // clientType: 2,
          materialId: this.catId,
          page: this.page,
          pageSize: this.pageSize,
        }
      }).then(res => {
        this.goodsList = type == 'up' ? [...this.goodsList, ...res.data.data.data] : res.data.data.data
        this.totalCount = res.data.data.totalCount
        that.click = true
      }).catch(err => {
        console.info(err)
        that.click = true
      })
    },
    handleChangeCat (id) {
      this.page = 1
      this.catId = id
      this.categoryList.map(item => {
        if (item.code === id) {
          item.selected = true
        } else {
          item.selected = false
        }
      })
      this.handleGetGoodsList()
    },
    // 点击商品
    goodsDetails (item) {
      let event = 'openGoodsInfo'
      let param = {
        'itemId': item.itemId,
        'itemSource': item.itemSource,
        'sellerId': item.sellerId,
        'module': 2
      }
      this.$h5AppLink(event, param)
    },
    // 获取url
    handleGetUrl () {
      if (this.url) {
        this.click = true
        setTimeout(() => {
          this.handleJumpTb()
        })
        return
      }
      this.$axios.post(api.strategyUrl, {
        tbActivityMaterialId: -1,
        initUrl: 'https://mo.m.taobao.com/union/yushoujianhuotob?spm=a211vu.11758832.0.0.35cb3e6fl9iGrv&__t__=1606744667553&show_tab=0'
      }).then(res => {
        this.url = res.data.data.spreadUrl
        this.shareContent = res.data.data.shareContent
        this.click = true
        setTimeout(() => {
          this.handleJumpTb()
        })
      }).catch(err => {
        Toast.fail(err.data.message)
        this.click = true
        console.log(err)
      })
    },

    // 唤醒淘宝app
    handleJumpTb () {
      let paramObj = {
        version: this.version,
        url: this.url,
        backUrl: `${baseUrl.pageUrl}/advance`
      }
      this.$h5AppLink('version', paramObj)
    },

    //判断授权
    isAuth () {
      if (!this.click) {
        return false
      }
      this.click = false
      this.$axios(api.auth, {}).then(res => {
        this.click = true
        if (res.data.data.authStatus == 0) {
          let event = 'openTbAuth'
          let param = {
            authUrl: res.data.data.authUrl,
            backUrl: `${baseUrl.pageUrl}/advance`
          }
          this.$h5AppLink(event, param)
        } else {
          this.authStatus = true
          this.handleGetUrl()
        }
      }).catch(err => {
        this.click = true
        this.isLogin()
      })
    },

    // 判断登陆
    isLogin () {
      let event = 'login'
      let param = {
        backUrl: `${baseUrl.pageUrl}/advance`
      }
      this.$h5AppLink(event, param)
    },

  }
}
</script>
<style>
</style>
<style lang="less" scoped>
/deep/ .advance-button {
  .van-button__text {
    display: flex;
    align-items: center;

    .van-icon {
      font-weight: 800;
      margin-left: 4px;
    }
  }
}

.advance {
  min-height: 100vh;

  .advance-banner {
    line-height: 0;

    img {
      width: 100%;
    }
  }

  .advance-button {
    margin-top: -2px;
    width: 100%;
    height: 55px;
    background: #8d13ff;
    text-align: center;

    .van-button {
      padding: 14px 34px;
      background: #FFDD77;
      border-radius: 25px;
      color: #D30617;
      font-weight: 600;
      font-size: 18px;
      line-height: 25px;
      border: none;
    }
  }

  .category-list {
    background-color: #8d13ff;
    color: #ffffff;
    width: 100%;
    height: 45px;
    white-space: nowrap;
    overflow-x: auto;

    .scroll-view-item {
      margin-right: 28px;
      display: inline-block;
      color: #ffffff;

      & > p:first-child {
        margin-left: 5px;
        font-size: 15px;
        line-height: 20px;
      }

      .active-tab {
        margin-left: 5px;
        width: 20px;
        height: 4px;
        background: #ffffff;
        border-radius: 2px;
      }
    }

    scroll-view-item:first-child {
      padding-left: 5px;
    }

    .scroll-view-item-active {
      & > p:first-child {
        font-size: 20px;
        font-weight: bold;
        line-height: 30px;
      }
    }
  }

  .goods-list {
    background: #8d13ff;
    width: 100%;
    min-height: 532px;
    padding-top: 8px;
    padding-bottom: 60px;

    .goods-wrap {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      margin: 0px 8px 8px 8px;
      padding: 10px;
      max-height: 140px;
      background: #fff;
      border-radius: 8px;

      .goods-image {
        & > img {
          width: 120px;
          height: 120px;
          border-radius: 8px;
        }
      }

      .goods-info {
        margin-left: 10px;

        .goods-title {
          font-size: 14px;
          font-family: PingFang-SC-Regular, PingFang-SC;
          color: #333333;
          line-height: 24px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          -webkit-box-orient: vertical;

          & > span:first-child {
            position: relative;
            top: -1px;
            font-size: 12px;
            color: #fff;
            margin-right: 5px;
            border-radius: 2px;
          }

          .label-tb {
            font-size: 8px;
            padding: 1px 4px;
            background: #FF8502;
          }

          .label-tm {
            font-size: 8px;
            padding: 1px 4px;
            background: #E44A59;
          }

          .label-jd {
            font-size: 8px;
            padding: 1px 4px;
            background-color: #e44a59;
          }

          .label-pdd {
            font-size: 8px;
            padding: 1px 3px;
            background-color: #ff5d87;
          }

          .label-wph {
            font-size: 8px;
            padding: 1px 3px;
            background-color: #ed008c;
          }

          .label-sn {
            font-size: 8px;
            padding: 1px 3px;
            background-color: #ffaa02;
          }
        }

        .goods-discounts {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          height: 20px;
          font-size: 12px;
          font-family: PingFang-SC-Regular, PingFang-SC;
          /* color: #8b7f08; */
          line-height: 20px;
          margin-top: 3px;

          .goods-coupon,
          .goods-cashback {
            color: #fff;
            background-color: #E44A59;
            border-radius: 2px;
          }

          .goods-cashback {
            padding: 0 4px;
          }

          .goods-coupon {
            padding: 0 8px 0 8px;
            margin-right: 8px;
            position: relative;
            /* overflow: hidden; */
          }

          .goods-coupon:before {
            /* display: inline-block; */
            content: '';
            width: 5px;
            height: 5px;
            border-radius: 50%;
            background-color: #fff;
            position: absolute;
            top: 50%;
            left: -2px;
            transform: translate(0, -50%);
          }

          .goods-coupon:after {
            /* display: inline-block; */
            content: '';
            width: 5px;
            height: 5px;
            border-radius: 50%;
            background-color: #fff;
            position: absolute;
            top: 50%;
            right: -2px;
            transform: translate(0, -50%);
          }
        }

        .goods-price-volume {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 12px;

          .price {
            color: #999;
            text-decoration: line-through;
          }
        }

        .goods-promotion-volume {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .money-symbol {
            font-size: 13px;
            font-weight: bold;
            color: #e44a59;
            line-height: 18px;
          }

          .goods-current-price {
            height: 30px;
            font-size: 22px;
            font-weight: bold;
            color: #e44a59;
            line-height: 30px;
          }
        }

        .goods-price-volume .price,
        .goods-promotion-volume .volume,
        .goods-price-volume .volume {
          height: 17px;
          font-size: 12px;
          line-height: 17px;
        }

        .goods-promotion-volume .volume,
        .goods-price-volume .volume {
          color: #999;
        }
      }
    }

    .loading-wrap {
      font-size: 12px;
      line-height: 17px;
      color: #ffffff;
      text-align: center;
      padding: 15px 0;

      .loading {
        width: 35px;
        height: 35px;
        animation: loading 1s linear 0s infinite;
      }

      @keyframes loading {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    }

    .loading-wrap-white {
      margin-top: 6px;
      background-color: #8d13ff;
    }
  }

  .bottom {
    position: fixed;
    bottom: 0px;
    left: 0px;
    box-shadow: 0px -4px 4px 0px rgba(0, 0, 0, 0.1);
    width: 100%;
    height: 60px;
    background: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;

    .van-button {
      color: #ffffff;
      width: 300px;
      height: 45px;
      font-size: 20px;
      font-weight: 600;
      line-height: 25px;
      background: linear-gradient(90deg, #FF004F 0%, #FF5500 100%);
      box-shadow: 0px 2px 4px 0px rgba(255, 31, 50, 0.6);
      border-radius: 25px;
      border: none;
    }
  }
}
</style>
